import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import AuditScatterChart from "../components/AuditScatterChart";

export default ({ data }) => {
  let worst = {};
  let best = {};
  const cumilitiveStats = data.allAllJson.edges.reduce((acc, cur) => {
    const stats = cur.node.categories;
    Object.keys(stats).forEach(key => {
      if (stats[key].score) {
        if (acc[key]) {
          acc[key].score += stats[key].score;
        } else {
          acc[key] = stats[key];
        }
        if (worst[key]) {
          if (worst[key].score > stats[key].score) {
            worst[key] = {
              score: stats[key].score,
              url: cur.node.requestedUrl,
              fetchTime: cur.node.fetchTime,
              count: 1
            };
          }
          if (worst[key].score === stats[key].score) {
            worst[key].count = worst[key].count + 1;
          }
        } else {
          worst[key] = {
            score: stats[key].score,
            url: cur.node.requestedUrl,
            fetchTime: cur.node.fetchTime,
            count: 1
          };
        }
        if (best[key]) {
          if (best[key].score < stats[key].score) {
            best[key] = {
              score: stats[key].score,
              url: cur.node.requestedUrl,
              fetchTime: cur.node.fetchTime,
              count: 1
            };
          }
          if (best[key].score === stats[key].score) {
            best[key].count = best[key].count + 1;
          }
        } else {
          best[key] = {
            score: stats[key].score,
            url: cur.node.requestedUrl,
            fetchTime: cur.node.fetchTime,
            count: 1
          };
        }
      }
    });
    return acc;
  }, {});
  let avgStats = cumilitiveStats;
  Object.keys(cumilitiveStats).map(
    item =>
      (avgStats[item].score =
        cumilitiveStats[item].score / data.allAllJson.edges.length)
  );
  return (
    <Layout>
      <SEO />
      <div className="pad-5-lr">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="is-blue is-white-bg pad-15-t pad-10-b">
                <h1 className="is-hero-menu">
                  Results of Auditing the First 100 Pages of the Top
                  100 Websites 🔎
                </h1>
                <div className="line" />
                <h4>
                  We all frequent the same sites but are they setting good
                  standards of accessibility and performance? Does a site's
                  popularity correlate with these standards? Let's find out 💪.
                  <br />
                  <br />I decided to audit 100 pages per site. This would be a
                  good amount of data to give each site an average. As I'm sure
                  you'll agree, assessing 10,000 pages manually would be far too
                  time consuming. Luckily, with a little automation and the
                  power of lighthouse we can audit them all with a click of a
                  button instead.
                </h4>
                <a
                  href={
                    "https://medium.com/@samlarsendisney/auditing-the-first-100-pages-of-the-top-100-websites-2e30c4d36847"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="is-pink"
                >
                  <h4>Read the full article here.</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="is-light-grey-bg pad-10-tb pad-5-lr lato">
        <div className="container">
          <div className="is-blue">
            <div className="row">
              <div className="col-xs-12 margin-5-b">
                <h1 className="is-hero-menu">
                  Average Lighthouse Scores Across All Pages
                </h1>
                <div className="line" />
              </div>

              {Object.keys(avgStats).map(item => (
                <div className="col-xs-6 col-md-3" key={item}>
                  <h4>{item.split("_").join(" ")}</h4>
                  <h1 className="is-hero-menu margin-0-t">
                    {avgStats[item].score.toFixed(2)}
                  </h1>
                </div>
              ))}
              <div className="col-xs-12">
                <hr />
              </div>
              <div className="col-xs-12">
                <h1 className="">👍 Highest Performing Pages By Category</h1>
              </div>
              {Object.keys(best).map(item => (
                <>
                  <div className="col-xs-12 col-md-2" key={item}>
                    <h4>{item.split("_").join(" ")}</h4>
                    <h1 className="is-hero-menu margin-0-t">
                      {best[item].score}
                    </h1>
                  </div>
                  <div
                    className="col-xs-12 col-md-10 flex"
                    style={{
                      justifyContent: "center",
                      flexDirection: "column"
                    }}
                  >
                    <h2>
                      <a
                        href={best[item].url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="is-pink"
                        style={{ wordWrap: "break-word" }}
                      >
                        {best[item].url}
                      </a>{" "}
                      {best[item].count > 1
                        ? ` & ${best[item].count} more.`
                        : ""}
                    </h2>
                    <p>Audited at {worst[item].fetchTime}</p>
                  </div>
                  <div className="col-xs-12" style={{ opacity: 0.3 }}>
                    <hr />
                  </div>
                </>
              ))}

              <div className="col-xs-12">
                <h1 className="">👎 Lowest Results Collected Per Category</h1>
                <p className="">
                  These are the lowest individual scores retrieved from the
                  audits. I have kept them intentionally anonymous.
                </p>
              </div>
              {Object.keys(worst).map(item => (
                <>
                  <div className="col-xs-6 col-md-3" key={item}>
                    <h4>{item.split("_").join(" ")}</h4>
                    <h1 className="is-hero-menu margin-0-t">
                      {worst[item].score}
                    </h1>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="pad-10-tb pad-5-lr lato">
        <div className="container">
          <AuditScatterChart data={data} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allAllJson {
      edges {
        node {
          categories {
            accessibility {
              score
            }
            performance {
              score
            }
            best_practices {
              score
            }
            seo {
              score
            }
          }
          id
          fetchTime
          requestedUrl
        }
      }
    }
  }
`;
