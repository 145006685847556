import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";

export default ({ data }) => {
  let AccessVsPerform = [];
  let AccessVsSeo = [];
  let PracticesVsPerform = [];
  data.allAllJson.edges.forEach(({ node }) => {
    AccessVsPerform.push({
      x: node.categories.accessibility.score,
      y: node.categories.performance.score
    });
    AccessVsSeo.push({
      x: node.categories.accessibility.score,
      y: node.categories.seo.score
    });
    PracticesVsPerform.push({
      x: node.categories.performance.score,
      y: node.categories["best_practices"].score
    });
  });

  return (
    <>
      <ResponsiveContainer width={"100%"} height={600}>
        <ScatterChart
          width={500}
          height={400}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="x" name="Accessibility" unit="">
            <Label
              value="Accessibility of Page"
              offset={-10}
              position="insideBottom"
            />
          </XAxis>
          <YAxis
            yAxisId="left"
            type="number"
            dataKey="y"
            name="Performance"
            unit=""
            label={{
              value: "Performance of Page",
              angle: -90,
              position: "insideLeft"
            }}
          />
          <Scatter
            yAxisId="left"
            data={AccessVsPerform}
            fill="#ea4e6850"
            isAnimationActive={false}
          />
        </ScatterChart>
      </ResponsiveContainer>
      <div className="pad-10-t" />
      <ResponsiveContainer width={"100%"} height={600}>
        <ScatterChart
          width={500}
          height={400}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="x" name="Accessibility" unit="">
            <Label
              value="Accessibility of Page"
              offset={-10}
              position="insideBottom"
            />
          </XAxis>
          <YAxis
            yAxisId="left"
            type="number"
            dataKey="y"
            name="SEO"
            unit=""
            label={{
              value: "SEO of Page",
              angle: -90,
              position: "insideLeft"
            }}
          />
          <Scatter
            yAxisId="left"
            data={AccessVsSeo}
            fill="#ea4e6850"
            isAnimationActive={false}
          />
        </ScatterChart>
      </ResponsiveContainer>
      <div className="pad-10-t" />
      <ResponsiveContainer width={"100%"} height={600}>
        <ScatterChart
          width={500}
          height={400}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="x" name="Performance" unit="">
            <Label
              value="Performance of Page"
              offset={-10}
              position="insideBottom"
            />
          </XAxis>
          <YAxis
            yAxisId="left"
            type="number"
            dataKey="y"
            name="Best Practices"
            unit=""
            label={{
              value: "Best Practices of Page",
              angle: -90,
              position: "insideLeft"
            }}
          />
          <Scatter
            yAxisId="left"
            data={PracticesVsPerform}
            fill="#ea4e6850"
            isAnimationActive={false}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
};
